// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; import './autoload/jquery.parallax-scroll.js'; import './autoload/lazyload.js'; // eslint-disable-line

// Import local dependencies
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
import ScrollOut from 'scroll-out';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Init Jquery UI select
 */
function initJUIselect(elem) {
  let $field = $(elem);
  let $gfield = $field.closest('.gfield');
  let args = {
    // eslint-disable-next-line no-unused-vars
    change: function (e, ui) {
      $(this).trigger('change');
    },
  };
  if ($gfield.length) {
    args.appendTo = $gfield;
  }
  $field.selectmenu(args).on('change', function () {
    $(this).selectmenu('refresh');
  });
}

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      setTimeout(function () {
        if ($that.width() / width > $that.height() / height) {
          $(this).css({
            width: '100%',
            height: 'auto',
          });
        } else {
          $(this).css({
            width: ($that.height() * width) / height,
            height: '100%',
          });
        }
      }, 500);
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
var scrollOut;
$(document).on('ready', function () {
  /**
   * Slider about logos
   */
  $('.about-education__logos').slick({
    arrows: false,
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  /**
   * First open popup
   */
  if (sessionStorage.getItem('firstOpen') === '1') {
    console.log(sessionStorage.getItem('firstOpen'));
  } else {
    setTimeout(function () {
      $('a[data-src="#mainPopup"]').trigger('click');
      sessionStorage.setItem('firstOpen', '1');
    }, 30000);
    console.log(sessionStorage.getItem('firstOpen'));
  }

  /**
   * Detect element appearance in viewport
   */
  scrollOut = ScrollOut({
    offset: 0,
    threshold: 0,
    once: true,
    onShown: function (element) {
      if ($(element).is('.ease-order')) {
        $(element)
          .find('.ease-order__item')
          .each(function (i) {
            let $this = $(this);
            $(this).attr('data-scroll', '');
            window.setTimeout(function () {
              $this.attr('data-scroll', 'in');
            }, 300 * i);
          });
      }
    },
  });

  /**
   * Slider home services list
   */
  $('.home-services__list').slick({
    arrows: false,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 767,
        settings: 'unslick',
      },
    ],
  });

  /**
   * Slider CTA
   */
  $('.cta-slider').slick({
    arrows: false,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1023,
        settings: 'unslick',
      },
    ],
  });

  /**
   * Slider instagram
   */
  $('#sbi_images').slick({
    arrows: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  /**
   * Slider home testimonials
   */
  $('.home-testimonials__list').slick({
    arrows: true,
    dots: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  /**
   * Slider home control
   */
  $('.home-control__list').slick({
    arrows: true,
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: 'unslick',
      },
    ],
  });

  /**
   * Modal init
   */
  $('.js-modal').each(function () {
    $(this).fancybox({
      baseClass: 'fancybox-modal',
      touch: false,
      selector: '.js-modal',
      hash: false,
      backFocus: false,
      helpers: {
        overlay: {
          locked: true,
        },
      },
      arrows: false,
      keyboard: false,
      btnTpl: {
        smallBtn:
          '<button data-fancybox-close="" class="fancybox-button fancybox-button--close fancybox-close-small" title="Close">' +
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.1 95"><path d="M60.4,47.5,92.3,15.6a9.05,9.05,0,0,0,0-12.9,9.05,9.05,0,0,0-12.9,0L47.5,34.6,15.6,2.7a9.05,9.05,0,0,0-12.9,0,9.05,9.05,0,0,0,0,12.9L34.6,47.5,2.7,79.4a9.05,9.05,0,0,0,0,12.9,9.17,9.17,0,0,0,13,0L47.5,60.4,79.4,92.3a9.16,9.16,0,0,0,13-12.9Z"/></svg>' +
          '</button>',
      },
    });
  });

  /**
   * Video page filter by taxonomies
   */
  $('#videoTagSelect, #videoCatSelect').on('change', function () {
    let $this = $(this);
    window.location.href = $this.val();
  });

  /**
   * Anchor to choose projects
   */
  let winLoc = window.location.search;
  if ($('.videos-main__list').length && winLoc) {
    let margin = -120;
    setTimeout(function () {
      let destination = $('.videos-main__filter').offset().top + margin + 'px';
      $('body, html').animate({ scrollTop: destination }, 500);
    }, 900);
  }

  /**
   * Init Jquery UI select
   */
  $('select')
    .not(
      "#billing_state, #shipping_state, #billing_country, #shipping_country, [class*='woocommerce'], #product_cat"
    )
    .each(function () {
      initJUIselect(this);
    });

  $(document).on('gform_post_render', function (event, form_id) {
    removeFieldPlaceholder();

    $('#gform_' + form_id)
      .find('select')
      .each(function () {
        initJUIselect(this);
      });
  });

  /**
   * About learn more button
   */
  $('.about-top__right-btn').on('click', function (e) {
    e.preventDefault();
    $(this).hide().siblings('.about-top__right-content').slideDown();
  });

  /**
   * About education slider
   */
  $('.about-education__list').slick({
    arrows: true,
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  var scrollFixed;
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');

      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 1024) {
        scrollFixed = $(window).scrollTop();
        $('.body__inner').addClass('is-active');
      } else {
        $('.body__inner').removeClass('is-active');
        $(window).scrollTop(scrollFixed);
      }
    })
    .on('changed.zf.mediaquery', function () {
      $('.body__inner').removeClass('is-active');
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 1024) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  if (scrollOut) scrollOut.update();

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }

  // Sticky header
  if ($(this).scrollTop() > 1) {
    $('.header').addClass('sticky');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();

  if (
    $(window).width() < 1024 &&
    !$('.cta-slider').hasClass('slick-initialized')
  ) {
    $('.cta-slider').slick({
      arrows: false,
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 1023,
          settings: 'unslick',
        },
      ],
    });
  }

  if (
    $(window).width() < 768 &&
    !$('.home-services__list').hasClass('slick-initialized')
  ) {
    $('.home-services__list').slick({
      arrows: false,
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 767,
          settings: 'unslick',
        },
      ],
    });
  }
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here

  // Sticky header
  if ($(this).scrollTop() > 1) {
    $('.body__inner').addClass('sticky');
  } else {
    $('.body__inner').removeClass('sticky');
  }
});
